@import "../config";

// hide
.no-print, .hide, .bx-controls, .bx-clone
{
	display: none !important;
}

// bxSlider
.bx-wrapper, .bx-viewport
{
	height: auto !important;
	width: auto !important;
}

// reset
html, body
{
	color: $colorBlack;
	font-family: $fontSimple;
	font-size: $fontSize;
	line-height: $fontHeight;
	margin: 0;
	padding: 0;
}

// links
a
{
	color: $colorBlack;
	text-decoration: none;
}

// headers
h1, h2, h3, h4, h5, h6
{
	font-size: $fontSize + 6;
	font-weight: bold;
	line-height: $fontHeight + 6;
	margin: 0;
	padding: 0 0 10px 0;
}

h3
{
	font-size: $fontSize + 4;
	line-height: $fontHeight + 4;
}

h4
{
	font-size: $fontSize + 2;
	line-height: $fontHeight + 2;
}

h5
{
	font-size: $fontSize + 1;
	line-height: $fontHeight + 1;
	padding-bottom: 5px;
}

h6
{
	font-size: $fontSize;
	line-height: $fontHeight;
	padding-bottom: 5px;
}

// content
p
{
	margin: 0;
	padding: 0 0 15px 0;
}

ul, ol
{
	margin: 0;
	padding: 0 0 10px 25px;
	li
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
	ul, ol
	{
		margin-bottom: -5px;
		padding: 5px 0 0 25px;
	}
}

ul
{
	list-style: disc;
}

ol
{
	ol
	{
		list-style: lower-alpha;
		ol
		{
			list-style: lower-roman;
		}
	}
}

dl
{
	margin: 0;
	padding: 0 0 10px 0;
	dt
	{
		font-weight: bold;
		margin: 0;
		padding: 0 0 5px 0;
	}
	dd
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
}

img
{
	display: block;
	height: auto;
	margin: 0 auto 15px auto;
	padding: 0;
	width: auto;
	max-width: 100%;
	p &
	{
		margin-bottom: 0;
	}
}

blockquote
{
	border-left: 2px solid $colorGrey;
	font-size: $fontSize + 2;
	line-height: $fontHeight + 2;
	margin: 0 0 10px 0;
	padding: 10px;
	p
	{
		margin: 0;
		padding: 0 0 5px 0;
	}
	.quote-author
	{
		color: $colorGrey;
		font-size: $fontSize - 2;
		line-height: $fontHeight - 2;
	}
}

hr
{
	display: none;
}

table
{
	border: 0;
	border-collapse: collapse;
	font-size: $fontSize - 1;
	line-height: $fontHeight - 1;
	margin: 0 0 15px 0;
	padding: 0;
	width: 100%;
	th, td
	{
		margin: 0;
		padding: 5px;
	}
	tbody
	{
		th, td
		{
			border-top: 1px solid $colorDivider;
		}
	}
}